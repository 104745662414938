import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Header,
  Icon,
  IconButton,
  Icons,
  Input,
  Line,
  Menu,
  MenuItem,
  Modal,
  Overlay,
  OverlayBackdrop,
  OverlayCenter,
  PopOut,
  RectCords,
  Spinner,
  Text,
  Tooltip,
  TooltipProvider,
  as,
  color,
  config,
} from 'folds';
import {MatrixEvent, Room} from 'matrix-js-sdk';
import {Relations} from 'matrix-js-sdk/lib/models/relations';
import {MouseEvent} from 'react';
import {
  FormEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {useFocusWithin, useHover} from 'react-aria';
import {useTranslation} from 'react-i18next';
import {Drawer} from '~/app/atoms/drawer/Drawer';
import {EmojiBoard} from '~/app/components/emoji-board';
import {EventReaders} from '~/app/components/event-readers';
import {AvatarBase, MessageBase, ModernLayout, Time, Username} from '~/app/components/message';
import {UserAvatar} from '~/app/components/user-avatar';
import {AsyncStatus, useAsyncCallback} from '~/app/hooks/useAsyncCallback';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {useRecentEmoji} from '~/app/hooks/useRecentEmoji';
import {ScreenSize, useScreenSizeContext} from '~/app/hooks/useScreenSize';
import {CreateIssueForm} from '~/app/organisms/create-issue/CreateIssue';
import {MessageLayout, MessageSpacing} from '~/app/state/settings';
import {getMxIdLocalPart} from '~/app/utils/matrix';
import {canEditEvent, getMemberAvatarMxc, getMemberDisplayName} from '~/app/utils/room';
import {useDistinctProjectWorkers} from '~/queries/useProjectWorkers';
import {useProjectAndGroupIdFromWorkerChats} from '~/queries/useWorkerChats';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {CompanyWorker} from '~/types/worker';
import colorMXID from '~/util/colorMXID';
import {ReactionViewer} from '../reaction-viewer';
import {MessageEditor} from './MessageEditor';
import * as css from './styles.css';

export type ReactionHandler = (keyOrMxc: string, shortcode: string) => void;

type MessageQuickReactionsProps = {
  onReaction: ReactionHandler;
};

export const MessageQuickReactions = as<'div', MessageQuickReactionsProps>(
  ({onReaction, ...props}, ref) => {
    const mx = useMatrixClient();
    const recentEmojis = useRecentEmoji(mx, 4);

    const handleReaction = useCallback(
      (unicode: string, shortcode: string) => {
        mixpanelService.trackWithAction(
          () => onReaction(unicode, shortcode),
          MixpanelEvents.Message3DotAddReactionClick,
        );
      },
      [onReaction],
    );

    if (recentEmojis.length === 0) return <span />;
    return (
      <>
        <Box
          style={{padding: config.space.S200}}
          alignItems="Center"
          justifyContent="Center"
          gap="200"
          {...props}
          ref={ref}
        >
          {recentEmojis.map((emoji) => (
            <IconButton
              key={emoji.unicode}
              className={css.MessageQuickReaction}
              size="300"
              variant="SurfaceVariant"
              radii="Pill"
              title={emoji.shortcode}
              aria-label={emoji.shortcode}
              onClick={() => handleReaction(emoji.unicode, emoji.shortcode)}
            >
              <Text size="T500">{emoji.unicode}</Text>
            </IconButton>
          ))}
        </Box>
        <Line size="300" />
      </>
    );
  },
);

export const MessageAllReactionItem = as<
  'button',
  {
    room: Room;
    relations: Relations;
    onClose?: () => void;
  }
>(({room, relations, onClose, ...props}, ref) => {
  const {t} = useTranslation(['message']);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleOpenReactions = useCallback(() => {
    mixpanelService.trackWithAction(
      () => setOpen(true),
      MixpanelEvents.Message3DotViewReactionsClick,
    );
  }, [setOpen]);

  return (
    <>
      <Overlay
        onContextMenu={(evt: any) => {
          evt.stopPropagation();
        }}
        open={open}
        backdrop={<OverlayBackdrop />}
      >
        <OverlayCenter>
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              returnFocusOnDeactivate: false,
              onDeactivate: () => handleClose(),
              clickOutsideDeactivates: true,
            }}
          >
            <Modal variant="Surface" size="300">
              <ReactionViewer
                room={room}
                relations={relations}
                requestClose={() => setOpen(false)}
              />
            </Modal>
          </FocusTrap>
        </OverlayCenter>
      </Overlay>
      <MenuItem
        size="300"
        after={<Icon size="100" src={Icons.Smile} />}
        radii="300"
        onClick={handleOpenReactions}
        {...props}
        ref={ref}
        aria-pressed={open}
      >
        <Text className={css.MessageMenuItemText} as="span" size="T300" truncate>
          {t('popout.cta.view_reactions')}
        </Text>
      </MenuItem>
    </>
  );
});

export const MessageReadReceiptItem = as<
  'button',
  {
    room: Room;
    eventId: string;
    onClose?: () => void;
  }
>(({room, eventId, onClose, ...props}, ref) => {
  const {t} = useTranslation(['message']);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleOpenReadReceipts = useCallback(() => {
    mixpanelService.trackWithAction(
      () => setOpen(true),
      MixpanelEvents.Message3DotReadReceiptsClick,
    );
  }, [setOpen]);

  return (
    <>
      <Overlay open={open} backdrop={<OverlayBackdrop />}>
        <OverlayCenter>
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              onDeactivate: handleClose,
              clickOutsideDeactivates: true,
            }}
          >
            <Modal variant="Surface" size="300">
              <EventReaders room={room} eventId={eventId} requestClose={handleClose} />
            </Modal>
          </FocusTrap>
        </OverlayCenter>
      </Overlay>
      <MenuItem
        size="300"
        after={<Icon size="100" src={Icons.CheckTwice} />}
        radii="300"
        onClick={handleOpenReadReceipts}
        {...props}
        ref={ref}
        aria-pressed={open}
      >
        <Text className={css.MessageMenuItemText} as="span" size="T300" truncate>
          {t('popout.cta.read_receipts')}
        </Text>
      </MenuItem>
    </>
  );
});

export const CreateIssue = as<
  'button',
  {
    room: Room;
    eventId: string;
    onClose?: () => void;
  }
>(({room, eventId, onClose, ...props}, ref) => {
  const {t} = useTranslation(['message', 'common']);
  const [open, setOpen] = useState(false);
  const mx = useMatrixClient();
  const currentUserChatId = mx?.getUserId();
  const event: MatrixEvent | undefined = room.findEventById(eventId);
  const roomId = event?.getRoomId();
  const isCoreBot = event?.getSender()?.startsWith('@corebot:');

  const {data: chatData, isLoading: isChatLoading} = useProjectAndGroupIdFromWorkerChats(
    roomId ?? '',
  );
  const {projectWorkers = [], projectWorkersLoading} = useDistinctProjectWorkers(
    chatData?.projectId ?? '',
  );

  const findWorkerByMatrixEvent = useMemo(
    () => (workers: CompanyWorker[]) => {
      return workers.find((worker) => worker?.worker_full?.ext_chat_id === currentUserChatId);
    },
    [currentUserChatId],
  );

  const currentUser = findWorkerByMatrixEvent(projectWorkers);

  const handleClose = useCallback(() => {
    mixpanelService.trackWithAction(() => {
      setOpen(false);
      onClose?.();
    }, MixpanelEvents.Message3DotCreateIssueXClick);
  }, [onClose]);

  const isDataReady =
    Boolean(chatData?.projectId) && Boolean(chatData?.groupId) && Boolean(currentUser);

  const isLoading = isChatLoading || projectWorkersLoading;
  const hasError = !isLoading && !isDataReady;

  const handleOpen = useCallback(() => {
    if (!hasError) {
      mixpanelService.trackWithAction(
        () => setOpen(true),
        MixpanelEvents.Message3DotCreateIssueClick,
      );
    }
  }, [hasError, setOpen]);

  return (
    <>
      <MenuItem
        size="300"
        variant={hasError ? 'Critical' : 'Surface'}
        after={<Icon size="100" src={Icons.Plus} />}
        radii="300"
        onClick={handleOpen}
        {...props}
        ref={ref}
        disabled={isCoreBot}
        aria-pressed={open}
      >
        <Text className={css.MessageMenuItemText} as="span" size="T300" truncate>
          {t('popout.cta.create_issue')}
          {isLoading && !isCoreBot && !hasError ? (
            <Spinner size="100" className={css.SpinnerOverrides} style={{visibility: 'visible'}} />
          ) : null}
          {hasError && !isCoreBot ? (
            <TooltipProvider
              position="Top"
              align="Center"
              tooltip={
                <Tooltip>
                  <Text truncate size="T300">
                    {t('common:generic_errors.oops')}
                  </Text>
                </Tooltip>
              }
            >
              {(ref) => (
                <Icon className={css.SpinnerOverrides} ref={ref} src={Icons.Info} size="100" />
              )}
            </TooltipProvider>
          ) : null}
        </Text>
      </MenuItem>

      {isDataReady ? (
        <Drawer isOpen={open} onClose={handleClose}>
          <Header variant="Surface" style={{paddingBottom: config.space.S400}} size="500">
            <Box grow="Yes">
              <Text size="H4">{t('drawer.create_issue.header.title')}</Text>
            </Box>
            <IconButton size="300" onClick={handleClose} variant="Primary" radii="300">
              <Icon src={Icons.Cross} />
            </IconButton>
          </Header>

          <CreateIssueForm
            currentUser={currentUser}
            eventId={eventId}
            handleClose={handleClose}
            projectId={chatData?.projectId ?? ''}
            groupId={chatData?.groupId ?? ''}
            room={room}
          />
        </Drawer>
      ) : null}
    </>
  );
});

export const MessageDeleteItem = as<
  'button',
  {
    room: Room;
    mEvent: MatrixEvent;
    onClose?: () => void;
  }
>(({room, mEvent, onClose, ...props}, ref) => {
  const {t} = useTranslation(['message']);
  const mx = useMatrixClient();
  const [open, setOpen] = useState(false);

  const [deleteState, deleteMessage] = useAsyncCallback(
    useCallback(
      (eventId: string, reason?: string) =>
        mx.redactEvent(room.roomId, eventId, undefined, reason ? {reason} : undefined),
      [mx, room],
    ),
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    const eventId = mEvent.getId();
    if (
      !eventId ||
      deleteState.status === AsyncStatus.Loading ||
      deleteState.status === AsyncStatus.Success
    )
      return;
    const target = evt.target as HTMLFormElement | undefined;
    const reasonInput = target?.reasonInput as HTMLInputElement | undefined;
    const reason = reasonInput && reasonInput.value.trim();
    deleteMessage(eventId, reason);
    mixpanelService.track(MixpanelEvents.Message3DotDeleteConfirmClick);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleOpenDelete = useCallback(() => {
    mixpanelService.trackWithAction(() => setOpen(true), MixpanelEvents.Message3DotDeleteClick);
  }, [setOpen]);

  return (
    <>
      <Overlay open={open} backdrop={<OverlayBackdrop />}>
        <OverlayCenter>
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              onDeactivate: handleClose,
              clickOutsideDeactivates: true,
            }}
          >
            <Dialog variant="Surface">
              <Header
                style={{
                  padding: `0 ${config.space.S200} 0 ${config.space.S400}`,
                  borderBottomWidth: config.borderWidth.B300,
                }}
                variant="Surface"
                size="500"
              >
                <Box grow="Yes">
                  <Text size="H4">{t('delete_message_modal.header.title')}</Text>
                </Box>
                <IconButton size="300" onClick={handleClose} radii="300">
                  <Icon src={Icons.Cross} />
                </IconButton>
              </Header>
              <Box
                as="form"
                onSubmit={handleSubmit}
                style={{padding: config.space.S400}}
                direction="Column"
                gap="400"
              >
                <Text priority="400">{t('delete_message_modal.body.message')}</Text>
                <Box direction="Column" gap="100">
                  <Text size="L400">
                    {t('delete_message_modal.body.form.fields.label')}
                    <Text as="span" size="T200">
                      ({t('delete_message_modal.requirement_labels.optional')})
                    </Text>
                  </Text>
                  <Input name="reasonInput" variant="Background" />
                  {deleteState.status === AsyncStatus.Error && (
                    <Text style={{color: color.Critical.Main}} size="T300">
                      ({t('delete_message_modal.body.error.message')})
                    </Text>
                  )}
                </Box>
                <Button
                  type="submit"
                  variant="Critical"
                  before={
                    deleteState.status === AsyncStatus.Loading ? (
                      <Spinner fill="Solid" variant="Critical" size="200" />
                    ) : undefined
                  }
                  aria-disabled={deleteState.status === AsyncStatus.Loading}
                >
                  <Text size="B400">
                    {deleteState.status === AsyncStatus.Loading
                      ? t('delete_message_modal.body.form.submit.submitting')
                      : t('delete_message_modal.body.form.submit.idle')}
                  </Text>
                </Button>
              </Box>
            </Dialog>
          </FocusTrap>
        </OverlayCenter>
      </Overlay>
      <Button
        variant="Critical"
        fill="None"
        size="300"
        after={<Icon size="100" src={Icons.Delete} />}
        radii="300"
        onClick={handleOpenDelete}
        aria-pressed={open}
        {...props}
        ref={ref}
      >
        <Text className={css.MessageMenuItemText} as="span" size="T300" truncate>
          {t('popout.cta.delete')}
        </Text>
      </Button>
    </>
  );
});

export type MessageProps = {
  canDelete?: boolean;
  canSendReaction?: boolean;
  collapse: boolean;
  edit?: boolean;
  highlight: boolean;
  imagePackRooms?: Room[];
  messageLayout: MessageLayout;
  messageSpacing: MessageSpacing;
  mEvent: MatrixEvent;
  onEditId?: (eventId?: string) => void;
  onReactionToggle: (targetEventId: string | undefined, key: string, shortcode?: string) => void;
  onReplyClick: MouseEventHandler<HTMLButtonElement>;
  onUserClick: MouseEventHandler<HTMLButtonElement>;
  onUsernameClick: MouseEventHandler<HTMLButtonElement>;
  reactions?: ReactNode;
  relations?: Relations | null;
  reply?: ReactNode;
  room: Room;
  shouldShowContextMenu?: boolean;
};

export const Message = as<'div', MessageProps>(
  (
    {
      canDelete,
      canSendReaction,
      children,
      className,
      collapse,
      edit,
      highlight,
      imagePackRooms,
      messageLayout,
      messageSpacing,
      mEvent,
      onEditId,
      onReactionToggle,
      onReplyClick,
      onUserClick,
      onUsernameClick,
      reactions,
      relations,
      reply,
      room,
      shouldShowContextMenu,
      ...props
    },
    ref,
  ) => {
    const {t} = useTranslation(['message']);
    const mx = useMatrixClient();
    const senderId = mEvent.getSender() ?? '';
    const [hover, setHover] = useState(false);
    const {hoverProps} = useHover({onHoverChange: setHover});
    const {focusWithinProps} = useFocusWithin({
      onFocusWithinChange: setHover,
    });
    const [menuAnchor, setMenuAnchor] = useState<RectCords>();
    const [emojiBoardAnchor, setEmojiBoardAnchor] = useState<RectCords>();

    const senderDisplayName =
      getMemberDisplayName(room, senderId) ?? getMxIdLocalPart(senderId) ?? senderId;
    const senderAvatarMxc = getMemberAvatarMxc(room, senderId);
    const isCurrentUser = senderId === mx.getUserId();
    const messageClassName = classNames(
      css.MessageBase,
      {[css.MessageBaseRight]: isCurrentUser},
      className,
    );
    const screenSize = useScreenSizeContext();
    const isMobile = screenSize === ScreenSize.Mobile;

    const headerJSX = !collapse && (
      <Box
        gap="300"
        className={isCurrentUser ? css.HeaderRight : css.HeaderLeft}
        direction={messageLayout === 1 ? 'RowReverse' : 'Row'}
        justifyContent="SpaceBetween"
        alignItems="Baseline"
        grow="Yes"
      >
        <Box shrink="No" direction="Column" gap="100">
          <Username
            as="button"
            style={{color: colorMXID(senderId)}}
            data-user-id={senderId}
            onContextMenu={onUserClick}
            onClick={onUsernameClick}
          >
            <Text as="span" size={messageLayout === 2 ? 'T300' : 'T400'} truncate>
              <b>{senderDisplayName}</b>
            </Text>
          </Username>
          <Box alignSelf={isCurrentUser ? 'End' : 'Start'}>
            <Time ts={mEvent.getTs()} compact={messageLayout === 1} />
          </Box>
        </Box>
      </Box>
    );

    const avatarJSX = !collapse && !isMobile && messageLayout !== 1 && (
      <AvatarBase className={isCurrentUser ? css.AvatarRight : css.AvatarLeft}>
        <Avatar
          title={senderDisplayName}
          className={css.MessageAvatar}
          as="button"
          size="300"
          data-user-id={senderId}
        >
          <UserAvatar
            userId={senderId}
            src={
              senderAvatarMxc
                ? (mx.mxcUrlToHttp(senderAvatarMxc, 48, 48, 'crop') ?? undefined)
                : undefined
            }
            alt={senderDisplayName}
            renderFallback={() => <Icon size="200" src={Icons.User} filled />}
          />
        </Avatar>
      </AvatarBase>
    );

    const msgContentJSX = (
      <Box direction="Column" alignSelf="Start" style={{width: '100%'}}>
        {reply}
        {edit && onEditId ? (
          <MessageEditor
            style={{
              maxWidth: '100%',
              width: '100vw',
            }}
            roomId={room.roomId}
            room={room}
            mEvent={mEvent}
            imagePackRooms={imagePackRooms}
            onCancel={() => onEditId()}
          />
        ) : (
          children
        )}
        {reactions}
      </Box>
    );

    const handleContextMenu: MouseEventHandler<HTMLDivElement> = (evt) => {
      if (evt.altKey || !window.getSelection()?.isCollapsed || edit) return;
      const tag = (evt.target as any).tagName;
      if (typeof tag === 'string' && tag.toLowerCase() === 'a') return;
      evt.preventDefault();
      setMenuAnchor({
        x: evt.clientX,
        y: evt.clientY,
        width: 0,
        height: 0,
      });
    };

    const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (evt) => {
      const target = evt.currentTarget.parentElement?.parentElement ?? evt.currentTarget;
      mixpanelService.trackWithAction(
        () => setMenuAnchor(target.getBoundingClientRect()),
        MixpanelEvents.Message3DotClick,
      );
    };

    const closeMenu = () => {
      setMenuAnchor(undefined);
    };

    const handleOpenEmojiBoard: MouseEventHandler<HTMLButtonElement> = (evt) => {
      const target = evt.currentTarget.parentElement?.parentElement ?? evt.currentTarget;
      setEmojiBoardAnchor(target.getBoundingClientRect());
      mixpanelService.track(MixpanelEvents.MessageContextReactionSelectClick);
    };

    const handleAddReactionsFromMenu: MouseEventHandler<HTMLButtonElement> = () => {
      const rect = menuAnchor;
      closeMenu();

      setTimeout(() => {
        mixpanelService.trackWithAction(
          () => setEmojiBoardAnchor(rect),
          MixpanelEvents.Message3DotAddReactionClick,
        );
      }, 100);
    };

    const handleReplyClick = useCallback(
      (evt: any) => {
        mixpanelService.trackWithAction(() => {
          onReplyClick(evt);
          closeMenu();
        }, MixpanelEvents.Message3DotReplyClick);
      },
      [onReplyClick, closeMenu],
    );

    const handleEditMessage = useCallback(() => {
      mixpanelService.trackWithAction(() => {
        onEditId?.(mEvent.getId());
        closeMenu();
      }, MixpanelEvents.Message3DotEditMessageClick);
    }, [onEditId, mEvent, closeMenu]);

    return (
      <MessageBase
        className={messageClassName}
        tabIndex={0}
        space={messageSpacing}
        collapse={collapse}
        highlight={highlight}
        selected={!!menuAnchor || !!emojiBoardAnchor}
        {...props}
        {...hoverProps}
        {...focusWithinProps}
        ref={ref}
      >
        {!edit && (hover || !!menuAnchor || !!emojiBoardAnchor) && (
          <div className={css.MessageOptionsBase}>
            {shouldShowContextMenu ? (
              <Menu className={css.MessageOptionsBar} variant="SurfaceVariant">
                <Box gap="100">
                  {canSendReaction && (
                    <PopOut
                      position="Bottom"
                      align={emojiBoardAnchor?.width === 0 ? 'Start' : 'End'}
                      offset={emojiBoardAnchor?.width === 0 ? 0 : undefined}
                      anchor={emojiBoardAnchor}
                      content={
                        <EmojiBoard
                          imagePackRooms={imagePackRooms ?? []}
                          returnFocusOnDeactivate={false}
                          allowTextCustomEmoji
                          onEmojiSelect={(key) => {
                            onReactionToggle(mEvent.getId(), key);
                            setEmojiBoardAnchor(undefined);
                            mixpanelService.track(MixpanelEvents.MessageContextReactionSelectClick);
                          }}
                          onCustomEmojiSelect={(mxc, shortcode) => {
                            onReactionToggle(mEvent.getId(), mxc, shortcode);
                            setEmojiBoardAnchor(undefined);
                          }}
                          requestClose={() => {
                            setEmojiBoardAnchor(undefined);
                          }}
                        />
                      }
                    >
                      <IconButton
                        onClick={handleOpenEmojiBoard}
                        variant="SurfaceVariant"
                        size="300"
                        radii="300"
                        aria-pressed={!!emojiBoardAnchor}
                      >
                        <Icon src={Icons.SmilePlus} size="100" />
                      </IconButton>
                    </PopOut>
                  )}
                  <IconButton
                    onClick={(event: MouseEvent<HTMLButtonElement>) => {
                      mixpanelService.trackWithAction(
                        () => onReplyClick(event),
                        MixpanelEvents.MessageContextReplyClick,
                      );
                    }}
                    data-event-id={mEvent.getId()}
                    variant="SurfaceVariant"
                    size="300"
                    radii="300"
                  >
                    <Icon src={Icons.ReplyArrow} size="100" />
                  </IconButton>
                  {canEditEvent(mx, mEvent) && onEditId && (
                    <IconButton
                      onClick={() => {
                        mixpanelService.trackWithAction(
                          () => onEditId(mEvent.getId()),
                          MixpanelEvents.MessageContextEditClick,
                        );
                      }}
                      variant="SurfaceVariant"
                      size="300"
                      radii="300"
                    >
                      <Icon src={Icons.Pencil} size="100" />
                    </IconButton>
                  )}
                  <PopOut
                    anchor={menuAnchor}
                    position="Bottom"
                    align={menuAnchor?.width === 0 ? 'Start' : 'End'}
                    offset={menuAnchor?.width === 0 ? 0 : undefined}
                    content={
                      <FocusTrap
                        focusTrapOptions={{
                          initialFocus: false,
                          onDeactivate: () => setMenuAnchor(undefined),
                          clickOutsideDeactivates: true,
                          isKeyForward: (evt: KeyboardEvent) => evt.key === 'ArrowDown',
                          isKeyBackward: (evt: KeyboardEvent) => evt.key === 'ArrowUp',
                        }}
                      >
                        <Menu>
                          {canSendReaction && (
                            <MessageQuickReactions
                              onReaction={(key, shortcode) => {
                                onReactionToggle(mEvent.getId(), key, shortcode);
                                closeMenu();
                              }}
                            />
                          )}
                          <Box direction="Column" gap="100" className={css.MessageMenuGroup}>
                            <CreateIssue
                              room={room}
                              eventId={mEvent.getId() ?? ''}
                              onClose={closeMenu}
                            />
                            {canSendReaction && (
                              <MenuItem
                                size="300"
                                after={<Icon size="100" src={Icons.SmilePlus} />}
                                radii="300"
                                onClick={handleAddReactionsFromMenu}
                              >
                                <Text
                                  className={css.MessageMenuItemText}
                                  as="span"
                                  size="T300"
                                  truncate
                                >
                                  {t('popout.cta.add_reaction')}
                                </Text>
                              </MenuItem>
                            )}
                            {relations && (
                              <MessageAllReactionItem
                                room={room}
                                relations={relations}
                                onClose={closeMenu}
                              />
                            )}
                            <MenuItem
                              size="300"
                              after={<Icon size="100" src={Icons.ReplyArrow} />}
                              radii="300"
                              data-event-id={mEvent.getId()}
                              onClick={handleReplyClick}
                            >
                              <Text
                                className={css.MessageMenuItemText}
                                as="span"
                                size="T300"
                                truncate
                              >
                                {t('popout.cta.reply')}
                              </Text>
                            </MenuItem>
                            {canEditEvent(mx, mEvent) && onEditId && (
                              <MenuItem
                                size="300"
                                after={<Icon size="100" src={Icons.Pencil} />}
                                radii="300"
                                data-event-id={mEvent.getId()}
                                onClick={handleEditMessage}
                              >
                                <Text
                                  className={css.MessageMenuItemText}
                                  as="span"
                                  size="T300"
                                  truncate
                                >
                                  {t('popout.cta.edit_message')}
                                </Text>
                              </MenuItem>
                            )}
                            <MessageReadReceiptItem
                              room={room}
                              eventId={mEvent.getId() ?? ''}
                              onClose={closeMenu}
                            />
                          </Box>
                          {((!mEvent.isRedacted() && canDelete) ||
                            mEvent.getSender() !== mx.getUserId()) && (
                            <Box direction="Column" gap="100" className={css.MessageMenuGroup}>
                              {!mEvent.isRedacted() && canDelete && (
                                <MessageDeleteItem
                                  room={room}
                                  mEvent={mEvent}
                                  onClose={closeMenu}
                                />
                              )}
                            </Box>
                          )}
                        </Menu>
                      </FocusTrap>
                    }
                  >
                    <IconButton
                      variant="SurfaceVariant"
                      size="300"
                      radii="300"
                      onClick={handleOpenMenu}
                      aria-pressed={!!menuAnchor}
                    >
                      <Icon src={Icons.VerticalDots} size="100" />
                    </IconButton>
                  </PopOut>
                </Box>
              </Menu>
            ) : null}
          </div>
        )}
        <ModernLayout
          before={avatarJSX}
          isCurrentUser={isCurrentUser}
          onContextMenu={handleContextMenu}
        >
          {headerJSX}
          {msgContentJSX}
        </ModernLayout>
      </MessageBase>
    );
  },
);

export type EventProps = {
  room: Room;
  mEvent: MatrixEvent;
  highlight: boolean;
  canDelete?: boolean;
  messageSpacing: MessageSpacing;
};

export const Event = as<'div', EventProps>(
  ({className, room, mEvent, highlight, canDelete, messageSpacing, children, ...props}, ref) => {
    const mx = useMatrixClient();
    const [hover, setHover] = useState(false);
    const {hoverProps} = useHover({onHoverChange: setHover});
    const {focusWithinProps} = useFocusWithin({
      onFocusWithinChange: setHover,
    });
    const [menuAnchor, setMenuAnchor] = useState<RectCords>();
    const stateEvent = typeof mEvent.getStateKey() === 'string';

    const handleContextMenu: MouseEventHandler<HTMLDivElement> = (evt) => {
      if (evt.altKey || !window.getSelection()?.isCollapsed) return;
      const tag = (evt.target as any).tagName;
      if (typeof tag === 'string' && tag.toLowerCase() === 'a') return;
      evt.preventDefault();
      setMenuAnchor({
        x: evt.clientX,
        y: evt.clientY,
        width: 0,
        height: 0,
      });
    };

    const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (evt) => {
      const target = evt.currentTarget.parentElement?.parentElement ?? evt.currentTarget;
      setMenuAnchor(target.getBoundingClientRect());
    };

    const closeMenu = () => {
      setMenuAnchor(undefined);
    };

    return (
      <MessageBase
        className={classNames(css.MessageBase, className)}
        tabIndex={0}
        space={messageSpacing}
        autoCollapse
        highlight={highlight}
        selected={!!menuAnchor}
        {...props}
        {...hoverProps}
        {...focusWithinProps}
        ref={ref}
      >
        {(hover || !!menuAnchor) && (
          <div className={css.MessageOptionsBase}>
            <Menu className={css.MessageOptionsBar} variant="SurfaceVariant">
              <Box gap="100">
                <PopOut
                  anchor={menuAnchor}
                  position="Bottom"
                  align={menuAnchor?.width === 0 ? 'Start' : 'End'}
                  offset={menuAnchor?.width === 0 ? 0 : undefined}
                  content={
                    <FocusTrap
                      focusTrapOptions={{
                        initialFocus: false,
                        onDeactivate: () => setMenuAnchor(undefined),
                        clickOutsideDeactivates: true,
                        isKeyForward: (evt: KeyboardEvent) => evt.key === 'ArrowDown',
                        isKeyBackward: (evt: KeyboardEvent) => evt.key === 'ArrowUp',
                      }}
                    >
                      <Menu {...props} ref={ref}>
                        <Box direction="Column" gap="100" className={css.MessageMenuGroup}>
                          <MessageReadReceiptItem
                            room={room}
                            eventId={mEvent.getId() ?? ''}
                            onClose={closeMenu}
                          />
                        </Box>
                        {((!mEvent.isRedacted() && canDelete && !stateEvent) ||
                          (mEvent.getSender() !== mx.getUserId() && !stateEvent)) && (
                          <>
                            <Line size="300" />
                            <Box direction="Column" gap="100" className={css.MessageMenuGroup}>
                              {!mEvent.isRedacted() && canDelete && (
                                <MessageDeleteItem
                                  room={room}
                                  mEvent={mEvent}
                                  onClose={closeMenu}
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </Menu>
                    </FocusTrap>
                  }
                >
                  <IconButton
                    variant="SurfaceVariant"
                    size="300"
                    radii="300"
                    onClick={handleOpenMenu}
                    aria-pressed={!!menuAnchor}
                  >
                    <Icon src={Icons.VerticalDots} size="100" />
                  </IconButton>
                </PopOut>
              </Box>
            </Menu>
          </div>
        )}
        <div onContextMenu={handleContextMenu}>{children}</div>
      </MessageBase>
    );
  },
);
