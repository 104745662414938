import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Icon, IconButton, Icons, Text} from 'folds';
import {useAtom} from 'jotai';
import {useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {toast} from 'sonner';
import RawModal from '~/app/atoms/modal/RawModal';
import {useGetProjectByRoomId} from '~/app/hooks/useGetProjectByRoomId';
import {workerAtom} from '~/app/state/worker';
import {useCreateRoomMutation} from '~/mutations/useCreateRoomMutation';
import {useEditRoomMutation} from '~/mutations/useEditRoomMutation';
import {useGroupInfoForRoom} from '~/queries/useGroupInfoForRoom';
import {useDistinctProjectWorkers} from '~/queries/useProjectWorkers';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {CompanyWorker} from '~/types/worker';
import {Member, MemberRoleMap} from '../../../services/ChatService/ChatService';
import s from './CreateRoom.module.scss';
import {GroupParticipantSelection} from './Steps/GroupParticipantSelection';
import {useWindowToggle} from './hooks/useWindowToggle';
import {createRoomFormSchema} from './schemas/createRoomFormSchema';
import {CreateRoomFormProps} from './types/types';
import {findMembersToAdd, findMembersToRemove} from './utils/groupMemberUtils';

type EditRoomHeaderProps = {
  loading: boolean;
  onRequestClose: () => void;
  projectName: string;
};

export type EditRoomFormProps = Omit<CreateRoomFormProps, 'selectedWorker'>;

const EditRoomHeader = ({loading, onRequestClose, projectName}: EditRoomHeaderProps) => {
  const {t} = useTranslation(['create_room', 'common']);
  return (
    <Box direction="Column">
      <Box direction="Row" justifyContent="SpaceBetween" alignItems="Center">
        <Text as="h3" size="H3" priority="300">
          {t('create_room:edit_room.modal.label')}
          <Text as="span" size="H3" priority="300">
            {loading ? t('common:loading') : projectName}
          </Text>
        </Text>
        <IconButton
          size="300"
          onClick={onRequestClose}
          radii="300"
          className={s.modal__selectedProject__header__iconBtn}
        >
          <Icon src={Icons.Cross} />
        </IconButton>
      </Box>
    </Box>
  );
};

export function EditRoom() {
  const {t} = useTranslation('create_room');
  const {isEditOpen, onRequestClose, roomId} = useWindowToggle();
  const [currentUser] = useAtom(workerAtom);

  const {data: project, isLoading: projectLoading} = useGetProjectByRoomId(roomId || '');
  const {data: groupInfo, isLoading: groupInfoLoading} = useGroupInfoForRoom(roomId || '');
  const isDirectMessage = groupInfo?.direct;
  const {projectWorkers} = useDistinctProjectWorkers(project?.id || '');

  const {mutateAsync: editRoomMutation} = useEditRoomMutation(roomId || '');
  const {mutateAsync: createRoomMutation} = useCreateRoomMutation({
    projectId: project?.id || '',
    onSuccess: () => {
      onRequestClose();
    },
    onError: () => {
      toast.error(t('edit_room.modal.toast.error'));
    },
  });

  const selectedWorkers = useMemo(() => {
    const map = new Map<string, CompanyWorker>();
    projectWorkers
      .filter((worker) => worker.worker_id !== currentUser.id)
      .forEach((wkr) => {
        map.set(wkr.worker_id, wkr);
      });
    if (!groupInfoLoading && groupInfo) {
      return groupInfo?.members
        .map((member) => map.get(member.member_id))
        .filter((member): member is CompanyWorker => member !== undefined);
    }
    return [];
  }, [projectWorkers, groupInfo, groupInfoLoading, currentUser]);

  const roomName = isDirectMessage ? `${groupInfo?.name} — Group` : groupInfo?.name;

  const methods = useForm<EditRoomFormProps>({
    mode: 'onChange',
    defaultValues: {
      roomName: '',
      selectedProject: undefined,
      selectedWorkers: [],
    },
    values: {
      roomName: roomName ?? '',
      selectedProject: project ?? {id: '', name: '', worker_count: 0},
      selectedWorkers,
    },
    resolver: zodResolver(createRoomFormSchema),
  });

  const onSubmit = async (data: EditRoomFormProps) => {
    const membersToRemove = findMembersToRemove(
      groupInfo?.members || [],
      data.selectedWorkers || [],
      currentUser.id,
    );
    const membersToAdd = findMembersToAdd(groupInfo?.members || [], data.selectedWorkers || []);

    const sharedParams = {
      adhoc: true,
      company_id: groupInfo?.company_id ?? '',
      direct: false,
      name: data.roomName,
      notifybot: true,
      project_id: project?.id ?? '',
    };

    if (isDirectMessage) {
      const members: Member[] = selectedWorkers.map((worker) => ({
        member_id: worker.worker_id,
        member_role: MemberRoleMap.member,
      }));
      await createRoomMutation({...sharedParams, members});
    } else {
      await editRoomMutation({
        ...sharedParams,
        group_id: groupInfo?.id ?? '',
        members: [],
        membersToAdd,
        membersToRemove,
      });
    }
    mixpanelService.track(MixpanelEvents.ChatRoomEditRoomSubmitClick);
    onRequestClose();
  };

  return (
    <RawModal
      className={s.modal}
      closeFromOutside={!methods.formState.isSubmitting}
      isOpen={isEditOpen}
      onRequestClose={onRequestClose}
    >
      <Box direction="Column" className={s.modal__layout}>
        <EditRoomHeader
          loading={projectLoading}
          projectName={project?.name || ''}
          onRequestClose={onRequestClose}
        />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <GroupParticipantSelection editing isDirectMessage={isDirectMessage} />
          </form>
        </FormProvider>
      </Box>
    </RawModal>
  );
}
