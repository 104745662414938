import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import {Box, Icon, Icons, Text} from 'folds';
import parse, {
  HTMLReactParserOptions,
  Element,
  domToReact,
  attributesToProps,
} from 'html-react-parser';

import Linkify from 'linkify-react';
import {Room} from 'matrix-js-sdk';
import {Fragment, useCallback, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {ScreenSize, useScreenSizeContext} from '~/app/hooks/useScreenSize';
import {
  LINKIFY_OPTS,
  highlightText,
  scaleSystemEmoji,
} from '~/app/plugins/react-custom-html-parser';
import * as css from '~/app/styles/CustomHtml.css';
import {getCanonicalAliasRoomId, getMxIdLocalPart} from '~/app/utils/matrix';
import {formatWbsPath} from '~/app/utils/osk';
import {getMemberDisplayName} from '~/app/utils/room';
import {sanitizeCustomHtml} from '~/app/utils/sanitize';
import {
  DATA_BYCORE_ISSUE_ID,
  DATA_BYCORE_TASK_ID,
  DATA_ISSUE_NAME,
  DATA_ISSUE_STATUS,
  DATA_TASK_NAME,
  DATA_TASK_STATUS,
  DATA_TASK_WBS_PATH,
} from '~/client/state/constants';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {IssueStatus} from '~/types/issue';
import {TaskStatus} from '~/types/taskStatus';
import {ENV} from '~/util/env';
import {IssueElement, TaskElement} from '../editor/slate';
import {MessageEmptyContent} from './content';

/**
 * !Do not Remove
 * Task Status
 * t('common:task_status.tba', 'TBA')
 * t('common:task_status.assigned', 'Assigned')
 * t('common:task_status.in_progress', 'In Progress')
 * t('common:task_status.blocked', 'Blocked')
 * t('common:task_status.unblocked', 'Unblocked')
 * t('common:task_status.done', 'Done')
 * t('common:task_status.not_done', 'Not Done')
 * t('common:task_status.verified', 'Verified')
 * t('common:task_status.closed', 'Closed')
 * t('common:task_status.rework', 'Rework')
 * t('common:task_status.approved', 'Approved')
 * t('common:task_status.archived', 'Archived')
 * Issue Status
 * t('common:issue_status.tba', 'TBA')
 * t('common:issue_status.in_progress', 'In Progress')
 * t('common:issue_status.closed', 'Closed')
 */

function decodeHTMLEntities(text: string): string {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

type RenderBodyProps = {
  body: string;
  customBody?: string;
  hasAttachments?: boolean;
  highlightRegex?: RegExp;
  htmlReactParserOptions: HTMLReactParserOptions;
  room: Room;
  projectId?: string | undefined;
  mEventId?: string;
};

export function RenderBody({
  body,
  customBody,
  hasAttachments,
  highlightRegex,
  htmlReactParserOptions,
  room,
  projectId,
  mEventId,
}: RenderBodyProps) {
  const mx = useMatrixClient();
  const issueMentionRef = useRef<Map<string, Partial<IssueElement>>>(new Map());
  const tasksMentionRef = useRef<Map<string, Partial<TaskElement>>>(new Map());
  const screenSize = useScreenSizeContext();
  const isMobile = screenSize === ScreenSize.Mobile;
  const isValidStatus = (s: string | undefined): s is IssueStatus =>
    Object.values(IssueStatus).includes(s as IssueStatus);
  const isValidTaskStatus = (s: string | undefined): s is TaskStatus =>
    Object.values(TaskStatus).includes(s as TaskStatus);
  const {t} = useTranslation();

  const renderContent = useMemo(() => {
    if (body === '' && !hasAttachments) return <MessageEmptyContent />;

    if (customBody) {
      const decodedFormattedBody = decodeHTMLEntities(customBody);
      const sanitizedFormattedBody = sanitizeCustomHtml(decodedFormattedBody);

      if (sanitizedFormattedBody === '') return <MessageEmptyContent />;

      const modifiedOptions: HTMLReactParserOptions = {
        ...htmlReactParserOptions,
        replace: (domNode) => {
          if (domNode instanceof Element && 'name' in domNode) {
            const {attribs, children} = domNode;
            const props = attributesToProps(attribs);
            const mention = decodeURIComponent(props.href).match(
              /^https?:\/\/matrix.to\/#\/((@|#|!).+:[^?/]+)/,
            );

            const isTaskMention = attribs[DATA_BYCORE_TASK_ID];
            const isIssueMention = attribs[DATA_BYCORE_ISSUE_ID];

            if (mention) {
              const [_fullMatch, mentionId, mentionPrefix] = mention;
              // convert mention link to pill
              if (mentionPrefix === '#' || mentionPrefix === '!') {
                const mentionRoom = mx.getRoom(
                  mentionPrefix === '#' ? getCanonicalAliasRoomId(mx, mentionId) : mentionId,
                );
                return (
                  <span
                    className={css.Mention({
                      highlight: room.roomId === (mentionRoom?.roomId ?? mentionId),
                    })}
                    data-mention-id={mentionRoom?.roomId ?? mentionId}
                    role="button"
                    style={{cursor: 'pointer'}}
                  >
                    {domToReact(children, modifiedOptions)}
                  </span>
                );
              }
              if (mentionPrefix === '@')
                return (
                  <span
                    className={css.Mention({highlight: mx.getUserId() === mentionId})}
                    data-mention-id={mentionId}
                    role="button"
                    style={{cursor: 'pointer'}}
                  >
                    {`@${getMemberDisplayName(room, mentionId) ?? getMxIdLocalPart(mentionId)}`}
                  </span>
                );
            }

            if (isTaskMention) {
              const taskId = attribs[DATA_BYCORE_TASK_ID];
              const taskStatus = attribs[DATA_TASK_STATUS];
              const taskName = attribs[DATA_TASK_NAME];
              const wbsPath = attribs[DATA_TASK_WBS_PATH];

              if (taskId) {
                tasksMentionRef.current.set(taskId, {
                  id: taskId,
                  status: taskStatus,
                  name: taskName,
                  wbsPath,
                });
              }

              return (
                <span {...props} className={css.TaskMention()}>
                  {domToReact(children, modifiedOptions)}
                </span>
              );
            }

            if (isIssueMention) {
              const issueId = attribs[DATA_BYCORE_ISSUE_ID];
              const issueStatus = attribs[DATA_ISSUE_STATUS];
              const issueName = attribs[DATA_ISSUE_NAME];

              if (issueId) {
                issueMentionRef.current.set(issueId, {
                  id: issueId,
                  status: issueStatus,
                  name: issueName,
                });
              }

              return (
                <span {...props} className={css.IssueMention()}>
                  {domToReact(children, modifiedOptions)}
                </span>
              );
            }
          } else if (domNode instanceof Element && domNode.name === 'a') {
            const href = domNode.attribs?.href;
            const props = attributesToProps(domNode.attribs);
            if (href) {
              const content =
                domNode.children.length > 0 && domNode.children[0].type === 'text'
                  ? domNode.children[0].data
                  : href;
              return (
                <Linkify {...props} options={LINKIFY_OPTS}>
                  {content}
                </Linkify>
              );
            }
          } else if ('data' in domNode && typeof domNode.data === 'string') {
            // Linkify text nodes
            return <Linkify options={LINKIFY_OPTS}>{domNode.data}</Linkify>;
          }
          return undefined; // Let html-react-parser handle other nodes
        },
      };

      return parse(sanitizedFormattedBody, modifiedOptions);
    }

    return (
      <Linkify options={LINKIFY_OPTS}>
        {highlightRegex
          ? highlightText(highlightRegex, scaleSystemEmoji(body))
          : scaleSystemEmoji(body)}
      </Linkify>
    );
  }, [
    body,
    customBody,
    hasAttachments,
    highlightRegex,
    htmlReactParserOptions,
    room,
    mx,
    issueMentionRef,
  ]);

  const handleMentionClick = useCallback((type: 'issue' | 'activity') => {
    if (type === 'issue') {
      mixpanelService.track(MixpanelEvents.MessageIssueMentionBannerClick);
    } else if (type === 'activity') {
      mixpanelService.track(MixpanelEvents.MessageActivityMentionBannerClick);
    }
  }, []);

  const issueMentionLinks = useMemo(() => {
    if (projectId === undefined) return null;

    const issues = Array.from(issueMentionRef.current.values());
    if (issues.length === 0) return null;

    return (
      <div className={css.MentionContainer}>
        {issues.map(({id: issueId, name, status}: Partial<IssueElement>) => {
          if (!issueId || !mEventId) return null;

          const searchParams = new URLSearchParams({
            eventId: mEventId,
            id: issueId,
            type: 'issue',
          });

          const encodedUrl = `${ENV.PUBLIC_C4_URL}/projects/${projectId}?${searchParams.toString()}`;
          const className = isValidStatus(status)
            ? css.IssueStatusBubble[status]
            : css.IssueStatusBubble.empty;

          return (
            <Fragment key={issueId}>
              <Box
                as="a"
                onClick={() => handleMentionClick('issue')}
                href={encodedUrl}
                target="_blank"
                className={css.IssueLinkContainer()}
              >
                <Box>
                  <Box title={t(`issue_status.${status}`)} as="span" className={cn(className)} />
                  <Text size="T300">{name}</Text>
                </Box>
                <Box>
                  <Icon className={css.LinkIcon} src={Icons.ChevronRight} />
                </Box>
              </Box>
            </Fragment>
          );
        })}
      </div>
    );
  }, [projectId]);

  const taskMentionsLinks = useMemo(() => {
    if (projectId === undefined) return null;

    const tasks = Array.from(tasksMentionRef.current.values());
    if (tasks.length === 0) return null;

    return (
      <div className={css.MentionContainer}>
        {tasks.map((task: Partial<TaskElement>) => {
          const {id: taskId, status, name, wbsPath} = task;

          if (!taskId || !mEventId) return null;

          const searchParams = new URLSearchParams({
            eventId: mEventId,
            id: taskId,
            type: 'task',
          });

          const formattedWbsPath = formatWbsPath(wbsPath, isMobile);

          const encodedUrl = `${ENV.PUBLIC_C4_URL}/projects/${projectId}?${searchParams.toString()}`;
          const className = isValidTaskStatus(status)
            ? css.TasksStatusBubble[status]
            : css.TasksStatusBubble.empty;

          return (
            <Fragment key={taskId}>
              <Box
                as="a"
                href={encodedUrl}
                onClick={() => handleMentionClick('activity')}
                target="_blank"
                className={css.TaskMentionContianer()}
              >
                <Box>
                  <Box title={t(`task_status.${status}`)} as="span" className={cn(className)} />
                  <Text
                    size="T300"
                    className={css.TaskMentionCopy}
                    title={wbsPath !== '' ? wbsPath : (name ?? '')}
                  >
                    {formattedWbsPath !== '' ? formattedWbsPath : (name ?? '')}
                  </Text>
                </Box>
                <Box className={css.IconContainer}>
                  <Icon className={css.LinkIcon} src={Icons.ChevronRight} />
                </Box>
              </Box>
            </Fragment>
          );
        })}
      </div>
    );
  }, [projectId, isMobile, mEventId, t]);

  return (
    <>
      {renderContent}
      {issueMentionLinks}
      {taskMentionsLinks}
    </>
  );
}
