import "src/app/styles/CustomHtml.css.ts.vanilla.css!=!../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/styles/CustomHtml.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+Va227jNhB9368gUBRoHmiIdmQ7WuxDtkV+oigKWhxJjClSoShfUvTfC11sUzdbtrcbQEGeMhoO58wcDjmTTLjZk4V00D9fEIqpDrnEK2WMij20ofo3jP8m88ddEAJJH76edIxKOhX+/TKpLHoB16nBfsQFs60XK52apqBdigc3bN1py1LDh8z2YTrYh1nxNaGMcRliAYHpgb9SmoHu1Jg+PaBUCc4quXqbM2fBimWBkganZi/AQ9xQwX1r88di80IloDEXew/FSqo0oT7ki30llPZqVvelM9Rfh1plktW/ZparLS/dLi93NjhNGc/SVgDe7dC6dsA85DSVta08H6Bso8EdiP1rPZz/STWnONGQpsC+GZ3BX4UflXGjqUwTqkEaa9XissrylK4qo1LpmApL5akOuMWkDonNRjooXmfZurVDsaq4v8NbzkzkIbKcuBriKyPqW8D5OxQnCCHBJeAIeBiZ+pkqT9z5rNKHHoJDle4dTiPK1DaPgtOKRE7m9mGzAudMyHQ40grctgLT0BB2LGAAOPLYh44sb4JHFrYLQenClTbANhEOQQG9KPhtKKKflqXow/AdXeD3Z+l1AIp1Hwh1Ewb501K0vgfcjRmStgPi/gTF91S6GzGwgffh5VT0X1R6yIsPodcsNTzYY19JA9J4qHi54BWYLYDMNQ7XjuP8mv/KeJoIuvdQIGCXC0LaZ9vAzmAGvtLUcCXzu1aCHXovUhvQRQIu6coBaXL70vT0A2qBuolqBUGONpJ7uHZbMfgUVDsF+ApGvd3DqNsr83F/fROhlG0iPQcUoW3EDeAiyrlwq2mSi/MIBSLfMOKMlZEvbJw+gBA8SXn6tfH0rBKT5wOHOtckljumfGPmH9NIc7muvymzS/1fSZOuvRjX4JfgfCWyWB7Z4FSP4eMum6u6zE3VZaoAm30Cl9bu7LXb5kv/f7tndz+g9uw//jn1bvdnzSJ11CLlPON4+LksupSVUP66/rCZOIdoH1oYUv66AW24TwWmgofSQzFnTNhnn5DOPQ51plWpfJAGdP6psIi5gTi1xQ0HYi6PZ6aUWJ0XmcxmpbDWfl3wHaFEpbw8AxoENXxTSAuaYmcyc6/uCcn0/ucTKacvDRB+ptM8yQwCmgm7ASflwKQbS99MZF7b0e1h8h6EUFuLwCtBc8qcVpazjCoxzmQ+bfLHllk5tMWN+LpllarNpiazxdFIKdedZ7w2lOucBJQ3ax9Nzx/m2hSBLD4T9MAuY8UtaIdi+ZlCUbvtyNPYoXdP/QgdO+5fnp38x8a8Gj1mx1l8f3mxMfufEDMbPeY/fp+5j66NGUaPuZ3nYPSYp8vnRT3P4egxv7w8u06tbkejx9zOMx895vb9/Dp6zG1ur0ePuc1tMXrMbW7HY8fc6DOK4dRbpgx8wP+iHJBVf9M/B7PoBv8DzF9t6jckAAA=\"}!../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var EmoticonBase = 'ity17n10 _1mqalmd1 _1mqalmd0';
export var Img = 'ity17nb _1mqalmd1 _1mqalmd0 ity17n0';
export var IssueMention = _7a468({defaultClassName:'ity17ng _1mqalmd1 _1mqalmd0',variantClassNames:{highlight:{true:'ity17nh'},focus:{true:'ity17ni'}},defaultVariants:{},compoundVariants:[]});
export var MarginSpaced = 'ity17n0';
export var TaskMention = _7a468({defaultClassName:'ity17nj _1mqalmd1 _1mqalmd0',variantClassNames:{highlight:{true:'ity17nk'},focus:{true:'ity17nl'}},defaultVariants:{},compoundVariants:[]});
export var Command = _7a468({defaultClassName:'ity17nw _1mqalmd1 _1mqalmd0',variantClassNames:{focus:{true:'ity17nx'},active:{true:'ity17ny'}},defaultVariants:{},compoundVariants:[]});
export var EmoticonImg = 'ity17n13 _1mqalmd1 _1mqalmd0';
export var IconContainer = 'ity17nt';
export var MentionContainer = 'ity17nv';
export var Heading = 'ity17n2 _1mqalmd1 _1mqalmd0 ity17n0';
export var Code = 'ity17n5 _1mqalmd1 _1mqalmd0 ity17n4';
export var IssueStatusBubble = {tba:'ity17n16',in_progress:'ity17n17',closed:'ity17n18',empty:'ity17n19'};
export var LinkIcon = 'ity17nz';
export var IssueSkeletonLinkContainer = 'ity17nu';
export var List = 'ity17na _1mqalmd1 _1mqalmd0 ity17n0';
export var Mention = _7a468({defaultClassName:'ity17nd _1mqalmd1 _1mqalmd0',variantClassNames:{highlight:{true:'ity17ne'},focus:{true:'ity17nf'}},defaultVariants:{},compoundVariants:[]});
export var TaskMentionCopy = 'ity17ns';
export var CodeBlockInternal = 'ity17n9';
export var TasksStatusBubble = {tba:'ity17n1a',assigned:'ity17n1b',in_progress:'ity17n1c',blocked:'ity17n1d',unblocked:'ity17n1e',done:'ity17n1f',not_done:'ity17n1g',verified:'ity17n1h',closed:'ity17n1i',rework:'ity17n1j',approved:'ity17n1k',archived:'ity17n1l',empty:'ity17n1m'};
export var InlineChromiumBugfix = 'ity17nc';
export var IssueLinkContainer = _7a468({defaultClassName:'ity17nm _1mqalmd1 _1mqalmd0',variantClassNames:{highlight:{true:'ity17nn'},focus:{true:'ity17no'}},defaultVariants:{},compoundVariants:[]});
export var highlightText = 'ity17n15 _1mqalmd1 _1mqalmd0';
export var Timeline = 'ity17n14';
export var CodeBlock = 'ity17n8 _1mqalmd1 _1mqalmd0 ity17n4 ity17n0';
export var Emoticon = _7a468({defaultClassName:'ity17n11 _1mqalmd1 _1mqalmd0',variantClassNames:{focus:{true:'ity17n12'}},defaultVariants:{},compoundVariants:[]});
export var Paragraph = '_1mqalmd1 _1mqalmd0';
export var BlockQuote = 'ity17n3 _1mqalmd1 _1mqalmd0 ity17n0';
export var Spoiler = _7a468({defaultClassName:'ity17n6 _1mqalmd1 _1mqalmd0',variantClassNames:{active:{true:'ity17n7'}},defaultVariants:{},compoundVariants:[]});
export var TaskMentionContianer = _7a468({defaultClassName:'ity17np _1mqalmd1 _1mqalmd0',variantClassNames:{highlight:{true:'ity17nq'},focus:{true:'ity17nr'}},defaultVariants:{},compoundVariants:[]});